import { Component, Input } from '@angular/core';
import { S1State } from '@app/shared/models/entita-base';
import { StatoRendiconto } from '@app/shared/models/rendiconto';
import { StatoTaskNegozio } from '@app/shared/models/task-negozio';
import { StatoUtente } from '@app/shared/models/utente';

@Component({
  selector: 'iwr-stato-tag',
  templateUrl: './stato-tag.component.html',
  styleUrls: ['./stato-tag.component.scss']
})
export class StatoTagComponent {

  @Input() stato: StatoRendiconto | StatoTaskNegozio | StatoUtente | S1State = StatoRendiconto.draft;

  styles: { [key in StatoRendiconto | StatoTaskNegozio | S1State | StatoUtente | string] : string };

  constructor() {

    this.styles = {
      [StatoRendiconto.draft] : 'badge-secondary',
      [StatoRendiconto.approved] :  'badge-success',
      [S1State.enabled] : 'badge-success',
      [S1State.disabled] : 'badge-danger',
      [StatoTaskNegozio.daFare]  : 'badge-secondary',
      [StatoTaskNegozio.pubblicato]  : 'badge-info',
      [StatoTaskNegozio.inLavorazione]  : 'badge-warning',
      [StatoTaskNegozio.chiuso]  : 'badge-black',
      [StatoTaskNegozio.completato]  : 'badge-success',
      [StatoTaskNegozio.inRevisione] : 'badge-danger',
      [StatoTaskNegozio.archiviato]:  'badge-white',
      [StatoUtente.pending] : 'badge-warning',
      [StatoUtente.change_pwd] : 'badge-info',
      [StatoUtente.blocked] : 'badge-warning',
      [StatoUtente.deleted] : 'badge-danger',
    }

  }

}
