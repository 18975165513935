import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { S1Modal, S1ButtonType, S1ModalSizes, S1UIService } from '@app/s1';
import { ITaskNegozio, StatoTaskNegozio } from '@app/shared/models/task-negozio';
import { TaskNegozioService } from '@app/shared/services/task-negozio.service';
import { AllegatoType } from '../allegato-tag/allegato-tag.component';
import { UtenteService } from '@app/shared/services/utente.service';

@Component({
  selector: 'iwr-task-modal',
  templateUrl: './task-modal.component.html',
  styleUrls: ['./task-modal.component.scss']
})
export class TaskModalComponent {

  @Output() elementSaved = new EventEmitter<boolean>();

  @ViewChild('modal') modal: S1Modal;

  s1ButtonType = S1ButtonType;
  statoTaskNegozio = StatoTaskNegozio;
  allegatoType = AllegatoType;

  taskNegozio: ITaskNegozio;

  messageControl: UntypedFormControl = new FormControl(null, [Validators.required]);

  formData: FormData = new FormData();

  progress: number = 0;

  showTextArea: boolean = false;

  isNegozio: boolean = false;

  atLeastOneResponse: boolean = false;

  constructor(private formBuilder: UntypedFormBuilder, private router: Router, private utenteService: UtenteService, private taskNegozioService: TaskNegozioService, private ui: S1UIService) { 

    this.utenteService.getCurrentUser().subscribe(user => {

      this.isNegozio = user?.codiceOperatoreRichiesto;

    })

  }

  ngOnInit(): void { }

  open(taskNegozio: ITaskNegozio, setInLavorazione: boolean) {

    this.taskNegozio = taskNegozio;
    this.resetForm();

    this.modal.open(S1ModalSizes.XL);

    if (taskNegozio.stato == StatoTaskNegozio.pubblicato && setInLavorazione) {
      this.setInLavorazione();
    } else {
      this.loadDetails();
    }

  }

  close() {
    this.modal.close();
  }

  setInLavorazione() {

    this.taskNegozioService.inLavorazione(this.taskNegozio?.id).subscribe(setted => {

      if (setted) {

        this.elementSaved.emit();
        this.loadDetails();

      }

    })

  }

  loadDetails() {

    this.taskNegozioService.dettaglio(this.taskNegozio?.id).subscribe(detail => {

      this.taskNegozio = detail;
      this.atLeastOneResponse = this.taskNegozio.risposte.some(risposta => risposta.operatoreCreatoDa);

    })

  }

  private resetForm() {
    this.messageControl.patchValue(null);
    this.formData = new FormData();
  }

  invia() {

    const response = { descrizione: this.messageControl.value };

    this.formData.set("rispostaTaskNegozio", JSON.stringify(response));

    this.taskNegozioService.risposta(this.taskNegozio?.id, this.formData).subscribe(uploaded => {

      this.progress = Number(uploaded);

      if (uploaded?.outcome?.success) {
        this.loadDetails();
        this.resetForm();
        this.showTextArea = false;
      }

    })

  }

  askAction(taskNegozio: ITaskNegozio, action: StatoTaskNegozio) {

    this.ui.showDialogPopup(`tasks.messages.${action}`).then(result => {
      if (result.value) {
        this.action(taskNegozio, action);
      }
    })

  }

  private action(taskNegozio: ITaskNegozio, action: StatoTaskNegozio) {

    this.taskNegozioService.cambiaStato(taskNegozio.id, action).subscribe(done => {

      if (done) {
        this.ui.showSuccessToast(`tasks.messages.${action}Done`);
        this.elementSaved.emit();
        this.loadDetails();
      }

    })

  }

  rispondi() {
    this.showTextArea = true;
  }

  resetTextArea() {
    this.messageControl.reset();
    this.showTextArea = false;
  }

}
