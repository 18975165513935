import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IS1InputSelectItem } from '@app/s1';
import { CategoriaService, IRicercaCategoriaParams } from '@app/shared/services/categoria.service';

@Component({
  selector: 'iwr-categoria-select',
  templateUrl: './categoria-select.component.html',
  styleUrls: ['./categoria-select.component.scss']
})
export class CategoriaSelectComponent {

  @Input() control: UntypedFormControl;
  @Input() label: string = "negozi.fields.negozio";
  @Input() readonly: boolean = false;
  @Input() multiple: boolean = false;

  categorie: IS1InputSelectItem[];

  constructor(private categoriaService: CategoriaService) {

    const params: IRicercaCategoriaParams = {
      page: 1,
      pageSize: 999,
      paginable: false
    }
  
    this.categoriaService.ricerca(params).subscribe(categoriePaginate => {

      this.categorie = categoriePaginate?.elements?.map(categoria => { 
        return { code: categoria.id, label: categoria.nome?.trim() }  
      }) ?? [];

      if (this.categorie?.length == 1) {
        this.control?.patchValue(this.categorie[0].code);
      }
  
    })

  }

}
