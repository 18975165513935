import { Component, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { S1ButtonType, S1Table, S1UIService } from '@app/s1';
import { ITask } from '@app/shared/models/task';
import { StatoTaskNegozio } from '@app/shared/models/task-negozio';
import { IRicercaTaskParams, TaskService } from '@app/shared/services/task.service';
import { UtilityService } from '@app/shared/services/utility.service';

@Component({
  selector: 'iwr-tabella-task',
  templateUrl: './tabella-task.component.html',
  styleUrls: ['./tabella-task.component.scss']
})
export class TabellaTaskComponent {

  @ViewChild('table') table: S1Table;

  s1ButtonType = S1ButtonType;
  statoTaskNegozio = StatoTaskNegozio;

  searchForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder, private router: Router, private taskService: TaskService, private utility: UtilityService, private ui: S1UIService) { 

    this.searchForm = this.formBuilder.group({
      'task': [null, null],
      'oggetto': [null, null],
      'priorita': [null, null],
      'daGiorno': [null, null],
      'aGiorno': [null, null],
    })

  }

  ngAfterViewInit(): void {
    this.table.config.sorting.sortBy = "data_ultima_modifica";
    this.table.config.sorting.isSortAscending = false; 
    this.search();
  }

  search() {

    let params: IRicercaTaskParams = {
      page: this.table?.config.pagination.page.page,
      pageSize: +this.table?.config.pagination.page.itemsPerPage,
      sort: this.table.sortBy(),
      dataDa: this.utility.stingFromDate(this.searchForm.controls.daGiorno.value),
      dataA: this.utility.stingFromDate(this.searchForm.controls.aGiorno.value),
      oggetto: this.searchForm.controls.oggetto.value,
      priorita: this.searchForm.controls.priorita.value,
      //idProgressive: this.searchForm.controls.task.value
    }

    this.taskService.ricerca(params).subscribe(tasksPaginati => {

      this.table.updateData(tasksPaginati.elements, tasksPaginati.paginationInfo);

    })

  }

  edit(task: ITask) {
    this.router.navigate([`/task/${task.id}`]);
  }

  askDelete(task: ITask) {

    this.ui.showDialogPopup("tasks.messages.delete").then(result => {
      if (result.value) {
        this.delete(task);
      }
    })

  }

  private delete(taskToDelete: ITask) {

    this.taskService.elimina(taskToDelete.id).subscribe(deleted => {

      if (deleted) {
        this.ui.showSuccessToast("tasks.messages.deleted");
        this.search();
      }

    })

  }

  askPublish(task: ITask) {

    this.ui.showDialogPopup("tasks.messages.publish").then(result => {
      if (result.value) {
        this.publish(task);
      }
    })

  }

  private publish(taskToPublish: ITask) {

    this.taskService.pubblica(taskToPublish.id).subscribe(published => {

      if (published) {
        this.ui.showSuccessToast("tasks.messages.published");
        this.search();
      }

    })

  }

}
