import { Injectable } from '@angular/core';
import { ITaskNegozio, StatoTaskNegozio } from '../models/task-negozio';
import { IS1SearchParams, IS1TypedPaginatedResult, S1ApiCrudService, S1HttpClientService } from '@app/s1';
import { IRicercaTaskParams } from './task.service';
import { INegozio } from '../models/negozio';
import { ITask } from '../models/task';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IRicercaTaskNegozioParams extends IS1SearchParams { 
  task: Partial<ITask>,
  negozio: Partial<INegozio>,
  stato: StatoTaskNegozio,
  dataDa: string,
  dataA: string
}

export interface IAllegatoTaskNegozioParams {
  idTaskNegozio: string,
  idRisposta: string,
  idAttachment: string
}

enum TaskNegozioAPI {
  risposta = "/risposta",
  attachment = "/attachment",
  archiviati = "/archiviati"
}

@Injectable({
  providedIn: 'root'
})
export class TaskNegozioService extends S1ApiCrudService<ITaskNegozio, IRicercaTaskNegozioParams> {

  constructor(s1HttpClient: S1HttpClientService) { 
    super(s1HttpClient, "/taskNegozio");
  }

  inLavorazione(idTaskNegozio: string): Observable<boolean> {
    return this.cambiaStato(idTaskNegozio, StatoTaskNegozio.inLavorazione);
  }

  completa(idTaskNegozio: string): Observable<boolean> {
    return this.cambiaStato(idTaskNegozio, StatoTaskNegozio.completato);
  }

  cambiaStato(idTaskNegozio: string, stato: StatoTaskNegozio): Observable<boolean> {
    return this.s1HttpClient.put(`${this.path}/${idTaskNegozio}/${stato}`, {}).pipe(
      map(response => response.outcome.success)
    )
  }

  risposta(idTaskNegozio: string, formData: FormData): Observable<any> {
    return this.s1HttpClient.upload(`${this.path}/${idTaskNegozio}${TaskNegozioAPI.risposta}`, formData).pipe(
      map(response => response)
    )
  }

  getAllegato(params: IAllegatoTaskNegozioParams): Observable<any> {
    return this.s1HttpClient.download(`${this.path}/${params.idTaskNegozio}${TaskNegozioAPI.risposta}/${params.idRisposta}${TaskNegozioAPI.attachment}/${params.idAttachment}`).pipe(
      map(response => response)
    )
  }

  ricercaArchiviati(params: IRicercaTaskNegozioParams): Observable<IS1TypedPaginatedResult<ITaskNegozio>> {
    return this.s1HttpClient.post(`${this.path}${TaskNegozioAPI.archiviati}`, params).pipe(
      map(response => {
        return  { 
          elements: response.results,
          paginationInfo: response.paginationInfo
        } as IS1TypedPaginatedResult<ITaskNegozio>
      })
    )
  }

}