<s1-modal #modal [title]="taskNegozio?.task?.oggetto">
  <div class="row p-2">

    <div class="col border rounded bg-gray text-right p-2 d-flex flex justify-content-between">
      <div class="d-flex flex-column text-left">
        <div class="text-bold pr-2">{{ 'tasks.modal.fields.utente' | translate }}</div>
        <div>{{ taskNegozio?.task?.utenteCreatoDa?.nomeCompleto }} {{ taskNegozio?.task?.operatoreCreatoDa?.nomeCompleto }}</div>
      </div>
      <div class="d-flex flex-column text-left">
        <div class="text-bold pr-2">{{ 'tasks.modal.fields.dataCreazione' | translate }}</div>
        <div>{{ taskNegozio?.task?.dataCreazione | s1ReadableDate: true }}</div>
      </div>
      <div class="d-flex flex-column text-left" *ngIf="taskNegozio?.task?.dataCreazione != taskNegozio?.task?.dataUltimaModifica">
        <div class="text-bold pr-2">{{ 'tasks.modal.fields.ultimaModifica' | translate }}</div>
        <div>{{ taskNegozio?.task?.utenteModificatoDa?.nomeCompleto }} {{ taskNegozio?.task?.operatoreModificatoDa?.nomeCompleto }} {{ taskNegozio?.task?.dataUltimaModifica | s1ReadableDate: true }}</div>
      </div>
      <div class="d-flex flex-column text-left" *ngIf="taskNegozio?.risposte?.length">
        <div class="text-bold pr-2">{{ 'tasks.modal.fields.ultimaRisposta' | translate }}</div>
        <div>{{ taskNegozio?.dataUltimaRisposta | s1ReadableDate: true }}</div>
      </div>
    <!--</div>

    <div class="col-auto border rounded bg-gray text-left p-2 mx-4 d-flex flex justify-content-center">-->
      <div class="d-flex flex-column text-left">
        <div class="text-bold pr-2">{{ 'Priorità' }}</div><iwr-priorita-tag [priorita]="taskNegozio?.task?.priorita"></iwr-priorita-tag>
      </div>
      <div class="d-flex flex-column  text-left">
        <div class="text-bold pr-2">Stato</div><iwr-stato-tag [stato]="taskNegozio?.stato"></iwr-stato-tag>
      </div>
    </div>
  </div>
  <div class="row p-2">
    <div class="col border rounded bg-gray text-left p-2 preserve-new-line">
      <div class="text-bold pr-2">{{ 'tasks.modal.fields.descrizione' | translate }}</div>
      {{ taskNegozio?.task?.descrizione }}
    </div>
    <div *ngIf="taskNegozio?.task?.allegati?.length" class="col-auto border rounded bg-gray text-left p-2 ml-4 d-flex flex-column">
      <div class="text-bold pr-2">{{ 'tasks.modal.fields.allegati' | translate }}</div>
      <iwr-allegato-tag class="mb-{{ (i == taskNegozio?.task?.allegati.length - 1) ? 0 : 2 }}" *ngFor="let allegato of taskNegozio?.task?.allegati; let i = index;" [idTask]="taskNegozio?.task?.id" [allegato]="allegato" [tipo]="allegatoType.task"></iwr-allegato-tag>
    </div>
  </div>
  <div class="row p-2">
    <div class="col border rounded bg-gray text-left p-2 preserve-new-line">
      <div class="text-bold pr-2">{{ 'tasks.modal.fields.categorie' | translate }}</div>
      <iwr-categoria-tag class="pr-2" *ngFor="let categoria of taskNegozio?.task?.categorie" [categoria]="categoria"></iwr-categoria-tag>
    </div>
  </div>
  <div class="row p-2" *ngFor="let risposta of taskNegozio?.risposte">
    <div class="{{ risposta.operatoreCreatoDa ? 'offset-2 bg-success text-right' : 'bg-info text-left' }} col-10 border rounded py-2 preserve-new-line">
      {{ risposta.descrizione }}<br/>
      <iwr-allegato-tag class="pl-2" *ngFor="let allegato of risposta?.allegati" [idTask]="taskNegozio?.id" [idRisposta]="risposta?.id" [allegato]="allegato" [tipo]="allegatoType.risposta"></iwr-allegato-tag>
      <div class="{{ risposta.operatoreCreatoDa ? 'text-left' : 'text-right' }} text-gray-light text-sm w-100">
        {{ risposta.utenteCreatoDa?.nomeCompleto }} {{ risposta.operatoreCreatoDa?.nomeCompleto }} {{ risposta.dataCreazione | s1ReadableDate: true }}
      </div>
    </div>
  </div>
  <div class="py-2" *ngIf="!showTextArea">
    <s1-button *ngIf="taskNegozio?.stato == statoTaskNegozio.inLavorazione || taskNegozio?.stato == statoTaskNegozio.inRevisione || (!isNegozio && taskNegozio?.stato != statoTaskNegozio.chiuso)" [type]="s1ButtonType.Edit" (onClick)="rispondi()" [label]="'tasks.buttons.rispondi'" [icon]="'fas fa-comment-alt'"></s1-button>
    <s1-button *ngIf="(taskNegozio?.stato == statoTaskNegozio.inLavorazione || taskNegozio?.stato == statoTaskNegozio.inRevisione) && (isNegozio ? atLeastOneResponse : true)" [type]="s1ButtonType.Link" (onClick)="askAction(taskNegozio, statoTaskNegozio.completato)" [label]="'tasks.buttons.completa'" [icon]="'fab fa-font-awesome-flag'" class="ml-2"></s1-button>
    <s1-button *ngIf="taskNegozio?.stato == statoTaskNegozio.completato" [type]="s1ButtonType.Delete" (onClick)="askAction(taskNegozio, statoTaskNegozio.inRevisione)" [label]="'tasks.buttons.revisione'" [icon]="'fas fa-exclamation-triangle'" class="ml-2"></s1-button>
    <s1-button *ngIf="!isNegozio && taskNegozio?.stato == statoTaskNegozio.completato" [type]="s1ButtonType.Cancel" (onClick)="askAction(taskNegozio, statoTaskNegozio.chiuso)" [label]="'tasks.buttons.chiudi'" [icon]="'fas fa-check'" class="ml-2"></s1-button>
    <s1-button *ngIf="!isNegozio && taskNegozio?.stato == statoTaskNegozio.chiuso" [type]="s1ButtonType.Import" (onClick)="askAction(taskNegozio, statoTaskNegozio.archiviato)" [label]="'tasks.buttons.archivia'" [icon]="'fas fa-archive'" class="ml-2"></s1-button>
  </div>
  <div class="row pt-2 border rounded" *ngIf="!progress && showTextArea">
    <!--<div class=" col ">-->
      <s1-text-area class="col" [control]="messageControl"></s1-text-area>
      <div class="col-auto d-flex flex-column align-items-center">
        <s1-button [type]="s1ButtonType.Send" [disabled]="messageControl.invalid" (onClick)="invia()"></s1-button>
        <s1-button class="pt-2" [type]="s1ButtonType.Cancel" (onClick)="resetTextArea()"></s1-button>
      </div>
      <s1-upload-section class="col-12" *ngIf="formData" [(formData)]="formData" [disabled]="progress" [allowRecordAudio]="true"></s1-upload-section>
    <!--</div>-->
    
  </div>
  <progressbar *ngIf="progress" class="progress-striped active" [animate]="true" [value]="progress" type="primary"><i>{{ progress }} %</i></progressbar>
</s1-modal>